<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <!-- /头部 -->

    <!-- 体部 -->
    <!-- 轮播图 -->
    <Carousel></Carousel>
    <!-- 内容-->
    <Body></Body>
    <!-- /体部 -->

    <!-- 尾部 -->
    <Footer></Footer>
    <!-- /尾部 -->

  </div>
</template>
 
<script>
import { get, post } from "@/utils/request";
import Carousel from "../components/Carousel";
  export default {
    components: {},
    data() {
      return {
    };
  },
  created() {},
  methods: {
  },

};
</script>

<style lang='scss'>
  // 体部样式


</style>